// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".anonymousSubmission-module__root--wPyB6{display:flex;align-items:flex-start;padding-top:8px}.anonymousSubmission-module__root--wPyB6>*:not(:last-child){margin-right:8px}", "",{"version":3,"sources":["webpack://./jsapp/js/components/anonymousSubmission.module.scss"],"names":[],"mappings":"AAEA,yCACE,YAAA,CACA,sBAAA,CACA,eAAA,CAGF,4DACE,gBAAA","sourcesContent":["@use 'scss/colors';\n\n.root {\n  display: flex;\n  align-items: flex-start;\n  padding-top: 8px;\n}\n\n.root > *:not(:last-child) {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "anonymousSubmission-module__root--wPyB6"
};
export default ___CSS_LOADER_EXPORT___;
