// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".updatePasswordForm-module__root--Kb3Uh{width:100%;max-width:350px}.updatePasswordForm-module__row--JszEm+.updatePasswordForm-module__row--JszEm{margin-top:16px}.updatePasswordForm-module__forgotLink--mKBxC{font-size:12px}.updatePasswordForm-module__guidanceText--Inv4O p{margin:0}.updatePasswordForm-module__guidanceText--Inv4O p:not(:first-child){margin-top:16px}", "",{"version":3,"sources":["webpack://./jsapp/js/account/security/password/updatePasswordForm.module.scss"],"names":[],"mappings":"AAAA,wCACE,UAAA,CACA,eAAA,CAGF,8EACE,eAAA,CAGF,8CACE,cAAA,CAKF,kDACE,QAAA,CAEA,oEACE,eAAA","sourcesContent":[".root {\n  width: 100%;\n  max-width: 350px;\n}\n\n.row + .row {\n  margin-top: 16px;\n}\n\n.forgotLink {\n  font-size: 12px;\n}\n\n// Note: we allow all markdown-generated HTML inside this, so we might need to\n// add some more styles here.\n.guidanceText :global p {\n  margin: 0;\n\n  &:not(:first-child) {\n    margin-top: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "updatePasswordForm-module__root--Kb3Uh",
	"row": "updatePasswordForm-module__row--JszEm",
	"forgotLink": "updatePasswordForm-module__forgotLink--mKBxC",
	"guidanceText": "updatePasswordForm-module__guidanceText--Inv4O"
};
export default ___CSS_LOADER_EXPORT___;
