// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectOneResponseForm-module__radioWrapper--ewy0V>*:not(:last-child){margin-bottom:10px}", "",{"version":3,"sources":["webpack://./jsapp/js/components/processing/analysis/responseForms/selectOneResponseForm.module.scss"],"names":[],"mappings":"AACE,sEACE,kBAAA","sourcesContent":[".radioWrapper {\n  > *:not(:last-child) {\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioWrapper": "selectOneResponseForm-module__radioWrapper--ewy0V"
};
export default ___CSS_LOADER_EXPORT___;
