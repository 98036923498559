// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sidebarDisplaySettings-module__root--_MC93{display:flex;justify-content:right;align-items:center;height:48px}.sidebarDisplaySettings-module__description--AuJI0{padding-bottom:36px}.sidebarDisplaySettings-module__display--FwICB:not(:last-child){padding-bottom:36px}.sidebarDisplaySettings-module__wrapWithParens--BnvKY span::before{content:\"(\"}.sidebarDisplaySettings-module__wrapWithParens--BnvKY span::after{content:\")\"}ul.sidebarDisplaySettings-module__options--r_nSC.sidebarDisplaySettings-module__options--r_nSC{margin:0}.sidebarDisplaySettings-module__questionList--evymL{margin:12px 0 0 36px}.sidebarDisplaySettings-module__checkbox--zsLHz{padding:12px;margin-top:6px;max-height:140px;border:1px solid #e1e3ea;border-radius:2px;overflow-y:auto}", "",{"version":3,"sources":["webpack://./jsapp/js/components/processing/sidebar/sidebarDisplaySettings.module.scss"],"names":[],"mappings":"AAEA,4CACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,WAAA,CAGF,mDACE,mBAAA,CAGF,gEACE,mBAAA,CAIA,mEACE,WAAA,CAGF,kEACE,WAAA,CAKJ,+FACE,QAAA,CAGF,oDACE,oBAAA,CAGF,gDACE,YAAA,CACA,cAAA,CACA,gBAAA,CACA,wBAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":["@use 'scss/colors';\n\n.root {\n  display: flex;\n  justify-content: right;\n  align-items: center;\n  height: 48px;\n}\n\n.description {\n  padding-bottom: 36px;\n}\n\n.display:not(:last-child) {\n  padding-bottom: 36px;\n}\n\n.wrapWithParens {\n  span::before {\n    content: '(';\n  }\n\n  span::after {\n    content: ')';\n  }\n}\n\n// We need to win the specificity here\nul.options.options {\n  margin: 0;\n}\n\n.questionList {\n  margin: 12px 0 0 36px;\n}\n\n.checkbox {\n  padding: 12px;\n  margin-top: 6px;\n  max-height: 140px; // ~4.5 times the size of a checkbox, makes 4 checkboxes visible\n  border: 1px solid colors.$kobo-gray-300;\n  border-radius: 2px;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "sidebarDisplaySettings-module__root--_MC93",
	"description": "sidebarDisplaySettings-module__description--AuJI0",
	"display": "sidebarDisplaySettings-module__display--FwICB",
	"wrapWithParens": "sidebarDisplaySettings-module__wrapWithParens--BnvKY",
	"options": "sidebarDisplaySettings-module__options--r_nSC",
	"questionList": "sidebarDisplaySettings-module__questionList--evymL",
	"checkbox": "sidebarDisplaySettings-module__checkbox--zsLHz"
};
export default ___CSS_LOADER_EXPORT___;
