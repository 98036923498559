// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sidebarSubmissionData-module__dataList--AmHy6{flex:1;padding:12px;border:1px solid #cacdd8;border-radius:6px}.sidebarSubmissionData-module__dataListBody--Ik7PJ{overflow-y:auto;height:100%}", "",{"version":3,"sources":["webpack://./jsapp/js/components/processing/sidebar/sidebarSubmissionData.module.scss"],"names":[],"mappings":"AAEA,+CACE,MAAA,CACA,YAAA,CACA,wBAAA,CACA,iBAAA,CAGF,mDACE,eAAA,CACA,WAAA","sourcesContent":["@use 'scss/colors';\n\n.dataList {\n  flex: 1;\n  padding: 12px;\n  border: 1px solid colors.$kobo-gray-400;\n  border-radius: 6px;\n}\n\n.dataListBody {\n  overflow-y: auto;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dataList": "sidebarSubmissionData-module__dataList--AmHy6",
	"dataListBody": "sidebarSubmissionData-module__dataListBody--Ik7PJ"
};
export default ___CSS_LOADER_EXPORT___;
