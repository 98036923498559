// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".analysisQuestionsList-module__root--jg6aJ{list-style:none}.analysisQuestionsList-module__root--jg6aJ>*:not(:last-child){margin-bottom:20px}", "",{"version":3,"sources":["webpack://./jsapp/js/components/processing/analysis/list/analysisQuestionsList.module.scss","webpack://./jsapp/js/components/processing/analysis/analysisVariables.scss"],"names":[],"mappings":"AAGA,2CACE,eAAA,CAEA,8DACE,kBCNO","sourcesContent":["@use 'scss/colors';\n@use '../analysisVariables';\n\n.root {\n  list-style: none;\n\n  > *:not(:last-child) {\n    margin-bottom: analysisVariables.$rows-gap;\n  }\n}\n","$icon-size: 32px;\n$rows-gap: 20px;\n$inner-vertical-gap: 10px;\n$inner-horizontal-gap: 8px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "analysisQuestionsList-module__root--jg6aJ"
};
export default ___CSS_LOADER_EXPORT___;
